Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.putAPiMethod = "PUT";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AutomaticFormCreation";
exports.labelBodyText = "AutomaticFormCreation Body";
exports.getFeedData = "bx_block_automaticformcreation/form_creations";
exports.putFormData = "bx_block_automaticformcreation/form_creations/"
exports.btnExampleTitle = "Submit";
exports.invoiceLable = "Sent Invoices";
exports.invoiceBlock="InvoiceBilling";
exports.ApprovedOwnersLable="Approved Owners"
exports.ApprovedOwnersBlock = "AutomaticFormCreation";
exports.chatLable="Chat with Keasy";
exports.chatBlock="Chat";
exports.vendorProfile  = "Vendor Profile";
exports.vendorLabelProfile = "UserProfileBasicBlock";
exports.projects = "Project History";
exports.vendorProjects = "Tasks";
// Customizable Area End