import React from "react";

import {
  // Customizable Area Start
  Container,
  Box,
  Button, // Customizable Area End
  Typography,
  adaptV4Theme,
} from "@mui/material";
import "@mui/styles";

// Customizable Area Start
import {
  CheckboxField,
  DatePickerField,
  TextInputField,
  TimePickerField,
} from "./components/AutomaticFields.web";
import {
  createTheme,
  ThemeProvider,
  Theme,
  styled,
  StyledEngineProvider,
} from "@mui/material/styles";
import { 
  activeProfile,
  profileMain,
  projects,
  activeProjects,
  invoice,
  key,
  keasyIcon,
  approved,
  activekey ,
  arrow
} from "./assets";
  import {
    TextField,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
  } from '@mui/material';
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";
import PopupMessage from "../../../components/src/PopupMessage.web";
import AutomaticFormCreationController, {
  Props,
  configJSON,
} from "./AutomaticFormCreationController.web";
import {ArrowDropDown, ArrowDropUp } from '@mui/icons-material';

import { FieldName } from "./types";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// Customizable Area End

export default class AutomaticFormCreation extends AutomaticFormCreationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderSortableTableCell = (property: string, label: string, unitIndex: number) => {
    return (
      <CustomTableCell key={property}>
        <Box display="flex" alignItems="center" justifyContent={"center"} fontFamily={"Outfit"} fontSize={"12px"} fontWeight={"700"}>
          {label}
          <Box display="flex" flexDirection="column" marginLeft={"10px"}>
            <CustomIconButtonUp
              data-test-id={`sortUpId,${unitIndex}`}
              onClick={() => this.handleSortRequest(property, "asc", unitIndex)}
            >
              <ArrowDropUp
              />
            </CustomIconButtonUp>
            <CustomIconButtonDown
              data-test-id={`sortDownId,${unitIndex}`}
              onClick={() => this.handleSortRequest(property, "desc", unitIndex)}
            >
              <ArrowDropDown
              />
            </CustomIconButtonDown>
          </Box>
        </Box>
      </CustomTableCell>
    );
  };

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
     <ApprovedOwnersStyle>
        <NavArea>
        <NavigationSidebar
          data-test-id={"navId"}
          activeItem={this.state.activeSideBarItem}
          sidebarItems={[
            { label: configJSON.vendorProfile, labelKey: configJSON.vendorLabelProfile, icon: profileMain, activeIcon: activeProfile, pathName: configJSON.vendorLabelProfile },
            { label: configJSON.projects, labelKey: configJSON.vendorProjects, icon: projects, activeIcon: activeProjects, pathName: configJSON.vendorProjects },
            { label: configJSON.invoiceLable, labelKey: configJSON.invoiceBlock, icon: invoice, activeIcon: invoice,pathName:configJSON.invoiceBlock },
            { label: configJSON.ApprovedOwnersLable, labelKey: configJSON.ApprovedOwnersBlock, icon: approved, activeIcon: approved,pathName:configJSON.ApprovedOwnersBlock },
            { label: configJSON.chatLable, labelKey: configJSON.chatBlock, icon: key, activeIcon: activekey,pathName:configJSON.chatBlock }
          ]}
          onClickSidebar={this.handleVendoritemClick}
          keasylogo={keasyIcon}
          openDrawer={this.state.openVendorDrawer}
          onClickDrawer={this.handleVendorDrawer}

        />
        </NavArea>
        <PopupMessage
          open={this.state.messageOpen}
          type={this.state.messageType}
          message={this.state.message}
          handleClose={this.handlePopupMessageClose}
        />
            <WorkArea 
               display={"flex"}
               flexDirection={"column"}
               justifyContent={"space-between"}
               width={"100%"}
             >
          <MainWorkContainer>
            <WorkStyles>
              <WorkOrderHead data-test-id="seeTextId">
                 Approved Owners
              </WorkOrderHead>
              <Grid item spacing={2}>
          
                <BorderContainer key={1}>
                <TableContainer >
                  <Table>
                    <TableHead>
                    <TableRow> 
                      {this.renderSortableTableCell("Profile picture", "Profile picture", 1)}
                      {this.renderSortableTableCell("Name", "Name", 3)}
                      {this.renderSortableTableCell("Email", "Email", 5)}
                      {this.renderSortableTableCell("Phone number ", "Phone number", 6)}
                      <TableCell></TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody  data-test-id="tableBody">
                       {this.state.approvedOwnersList.map((owner, index) => (
                          <TableRow key={index}>
                            <DetailsTableCell >
                              <img
                                src={owner.profilePicture}
                                alt={owner.name}
                                style={{ width: '32px', borderRadius: '100%',
                                         border: "1px solid #FFF1CC", }}
                              />
                            </DetailsTableCell>
                            <DetailsTableCell>{owner.name}</DetailsTableCell>
                            <DetailsTableCell>{owner.email}</DetailsTableCell>
                            <DetailsTableCell>{owner.phoneNumber}</DetailsTableCell>
                            <TableCell>
                              <IconButton data-test-id="arrow" onClick={() => this.handleArrowClick(owner)}>
                                <img src={arrow} alt="arrow icon" style={{ width: '20px' }} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                </BorderContainer>
              </Grid>
          
            </WorkStyles>
          </MainWorkContainer>
        </WorkArea>
     </ApprovedOwnersStyle>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  dynamicInputStyle: {
    width: "100%",
    height: "80px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  checkboxInputStyle: {
    width: "100%",
    height: "40px",
    marginLeft: "-10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
const ApprovedOwnersStyle = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  "@media(max-width: 992px)": {
    flexDirection: "column"
  },
  "&.webStyle": {
    backgroundColor: "red"
  },
  "& .bgImgWrapper": {
    display: "flex",
    justifyContent: "flex-end",
    width: "97%",
    marginTop: "2rem",
    "@media (max-width:1220px)": {
      width: "88%"
    },
    "@media (max-width:992px)": {
      display: "none",
    }
  },
  "& .backgroundImage": {
    width: "250px",
    "@media (max-width:1220px)": {
      width: "180px"
    },
  },
  "& .hexImg": {
    objectFit: "cover"
  }
});
const NavArea = styled(Box)({
  position: "fixed",
  top: 0,
  left: 0,
  height: "100%",
  overflowY: "auto",
  zIndex: 1000, 
  border: "1px solid rgb(208,203,199)",
  "&::-webkit-scrollbar": {
    display: "none"
  },
  "scrollbar-width": "none",
  "-ms-overflow-style": "none",
  "@media(max-width: 992px)": {
    position: "unset",
    height: "auto",
    overflowY: "hidden",
    border: "none",
  }
});
const WorkArea = styled(Box)({
  marginLeft: "90px", 
  flexGrow: 1,
  overflowY: "auto",
  height: "100vh",
  "@media(max-width: 992px)": {
    marginLeft: "0px", 
    height: "auto",
    overflowY: "unset"
  }
});
const CustomIconButtonUp = styled(Typography)({
  marginBottom: "-14px",
  cursor: "pointer"
});
const CustomIconButtonDown = styled(Typography)({
  marginTop: "0px",
  cursor: "pointer"
});
const CustomTableCell = styled(TableCell)({
  color: "#A3978F",
  textAlign: "center",
  fontSize: "12px",
  fontWeight: 700,
  padding: "0px 10px 0px 10px !important",
  whiteSpace: "nowrap"
});
const DetailsTableCell = styled(TableCell)({
  fontFamily:"Outfit",
  color: "#000000",
  fontSize: "12px",
  textAlign: "center",
  fontWeight: 400,
  whiteSpace: "nowrap",
  lineHeight:"12px"
});
const BorderContainer = styled(Box)({
  border: "1px solid #FFD466",
  borderRadius: "8px 8px 32px 8px",
  padding: "1rem",
  marginTop: "1rem",
  display: "flex",
  flexDirection: "column",
  "@media(max-width: 900px)": {
    marginTop:"0rem",
  },
  "& .scrollable-container": {
    height: "300px",
    overflowY: "auto",
    scrollbarWidth: "none",
  },
  "& .isUnitsDisabled": {
    display: "none"
  }
});
const MainWorkContainer = styled(Box)({
  width: "100%",
  display: "flex",
  fontFamily: "Outfit",
  alignItems: "center",
  flexDirection: "column",
  "@media(max-width: 992px)": {
    padding: "0px 0px 0px 0px"

  }
});
const PhotoTableCell = styled(Box)({
  width: "100%",
  display: "flex",
  fontFamily: "Outfit",
  alignItems: "center",
  flexDirection: "column",
  "@media(max-width: 992px)": {
    padding: "0px 0px 0px 0px",
  }
});
const WorkOrderHead = styled(Typography)({
  fontSize: "24px",
  fontWeight: 700,
  color: "#000000",
  marginBottom: "20px",
  fontFamily:"Outfit",
  lineHeight:"40px",
  letterSpacing:"-0.5%",
});
const WorkStyles = styled(Box)({
  width: "90%",
  marginTop: "42px",
  "& .search-main-content": {
    display: "flex",
    justifyContent: "space-between",
    "@media(max-width:600px)": {
      width: "100%",
      position: "relative",
      flexDirection: "column",
      display: "flex",
      "@media(max-width: 420px)": {
        gap: "5px"
      }
    },
  },
  "& .left": {
    display: "flex",
    flexWrap: "wrap", 
    gap: "1rem",
    "@media(max-width: 600px)": {
      display: "grid", 
      gridTemplateColumns: "1fr 1fr", 
      gap: "0.5rem",     },
  },
  "& .right": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "1rem",
    "@media(max-width: 600px)": {
      display: "grid", 
      gridTemplateColumns: "1fr 1fr", 
      gap: "0.5rem", 
    },
  },
 
  "& .icon": {
    mixBlendMode: "multiply"
  },
  "& .image-icon": {
    width: "16px",
    height: "16px"
  },
  "& .text-data": {
    fontSize: "16px",
    fontWeight: 700,
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .new-data": {
    fontSize: "18px",
    color: "#CC9200",
    fontWeight: 700,
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
 
});

// Customizable Area End
